
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto&display=swap');*/

body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eeeeee;
}

.nested {
  padding-left: 10px !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #7D98DA !important;
  color: #ffffff !important;
}

.MuiPickersToolbar-toolbar h1 {
  color:white !important;
}

.MuiPickersToolbar-toolbar h2 {
  color:white !important;
}

.MuiPickersToolbar-toolbar h3 {
  color:white !important;
}

.MuiPickersToolbar-toolbar h4 {
  color:white !important;
}

.MuiPickersToolbar-toolbar h5 {
  color:white !important;
}

.MuiPickersToolbar-toolbar h6 {
  color:white !important;
}

.MuiTabs-root {
  background-color: #7D98DA !important;
}

.MuiTabs-root .MuiSvgIcon-root {
  color: #ffffff !important;
}

#edit_activity .MuiButton-label {
  color: #7D98DA !important;
}

.MuiPickersDay-daySelected {
  background-color: #7D98DA !important;
  color: #ffffff !important;
}

.MuiPickersClockPointer-pointer {
  background-color: #7D98DA !important;

}
.MuiPickersClockNumber-clockNumberSelected {
  background-color: #7D98DA !important;
  color: #ffffff !important;

}
